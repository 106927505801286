<script>
  export let title;
  export let description;
  export let imgSrc;

  import { _ } from 'svelte-i18n';

  let showDescription = false;

  function toggle() {
    showDescription = !showDescription;
  }
</script>

<div class="service" on:click={toggle}>
  <div class="img-container">
    <img class="img" src={imgSrc} alt={$_(title)} />
  </div>
  <div class="content">
    <div class="title">{$_(title)}</div>
    <div class="description" class:hidden={!showDescription}>
      {$_(description)}
    </div>
    <div class="toggle">
      {#if !showDescription}
        {$_('common.readMore')}
      {:else}{$_('common.readLess')}{/if}
    </div>
  </div>
</div>

<style>
  .service {
    display: flex;
    align-items: flex-start;
    cursor: pointer;
  }
  .service:hover .title,
  .service:hover .toggle {
    opacity: 0.7;
  }
  .img-container {
    padding-top: 6px;
    width: 55px;
    margin-right: 25px;
    flex: 0 1 auto;
  }
  .img {
    object-fit: cover;
  }
  .content {
    flex: 1 1 auto;
  }
  .title {
    font-size: 18px;
    font-weight: 600;
  }
  .description {
    max-height: 1000px;
    line-height: 25px;
    overflow: hidden;
    transition: max-height 0.25s ease-in;
  }
  .description.hidden {
    transition: max-height 0.15s ease-out;
    max-height: 0;
  }
  .toggle {
    color: #929292;
  }
</style>
